@import 'src/theme/variables.scss';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.container {
  background-color: white;
}

.ratingContainer {
  line-height: 70px;
  border-top: 2px solid $brand-info;
  padding: 20px;

  .stars {
    vertical-align: middle;
    margin-bottom: 0px;
    transform: translateY(0px);
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);
    transition-delay: 0.1s;

    @media (min-width: $screen-sm) {
      margin-bottom: 0px;
      text-align: right;
    }

    img {
      max-width: 50%;
      max-height: 50px;

      @media (min-width: $screen-sm) {
        max-width: 80%;
      }

      @media (min-width: $screen-md) {
        max-width: 60%;
      }
    }

    &.hide {
      transition-delay: 0s;
      transform: translateY(10%);
      opacity: 0;
    }
  }

  .text {
    vertical-align: middle;
    transform: translateY(0px);
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);
    transition-delay: 0.1s;

    p {
      color: $brand-purple;
      font-size: 15px;
      font-weight: 700;
      margin: 12px 0px 0px 0px;

      // @media (min-width: $screen-sm) {
      //   font-size: 20px;
      // }

      // @media (min-width: $screen-md) {
      //   font-size: 22px;
      // }

      // @media (min-width: $screen-lg) {
      //   line-height: 60px;
      //   font-size: 24px;
      // }
    }

    .total {
      color: #666;
      font-size: 13px;
      font-weight: 400;
      margin: 0px 0px;
    }

    &.hide {
      transition-delay: 0s;
      transform: translateY(10%);
      opacity: 0;
    }
  }
}
