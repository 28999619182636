@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.container {
  display: block;
  height: 175px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  margin: 20px;
  border-radius: 4px;
  position: relative;

  .overlay {
    visibility: hidden;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in;
    position: relative;

    .overlayText {
      position: absolute;
      top: 25px;
      bottom: 25px;
      left: 10px;
      right: 10px;
      font-weight: 700;
      text-align: center;
      opacity: 1;
      color: #fff;
      z-index: 1;
    }

    .overlayBackground {
      width: 100%;
      height: 100%;
      background-color: $brand-purple;
      opacity: 0.9;
      border-radius: 4px;
    }
  }

  &:hover > .overlay {
    visibility: visible;
    opacity: 1;
  }

  .text {
    color: black;
    text-align: center;
    font-size: 16px;
    margin-top: 15px;

    span {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
    }
  }

  .text:hover {
    color: #399ef8;
    transition: all 0.2s ease-in;
  }
}

.illustration {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  height: 200px;
  width: 200px;
  background-size: 90%;
  border: 2px solid $brand-purple;
  border-radius: 30px;
  background-color: white;

  .overlay {
    .overlayText {
      top: 35px;
    }

    .overlayBackground {
      border-radius: 26px;
    }
  }
}
