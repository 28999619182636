@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.container {
  composes: container from global;
  padding: 10px 15px 50px;

  @media all and (min-width: $screen-md) {
    padding: 30px 15px 60px;
  }

  .getStartedButton {
    font-weight: 700;
    font-size: 20px;
    width: 100%;
    line-height: 1.7;

    @media all and (min-width: $screen-sm) {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 123px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.categories {
  //color: #464646;
  font-size: 18px;
  text-align: center;
  display: block;
  margin-top: 20px;
  margin-left: 15px;
  transition: all 0.3s ease;

  @media all and (min-width: $screen-sm) {
    text-align: left;
    margin-top: 0px;
  }
}

.bulletPoints {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: 15px;
  font-size: 18px;
  color: #333;

  li {
    margin-bottom: 15px;
    margin-left: 25px;
    position: relative;

    svg {
      color: $brand-success;
      margin-right: 5px;
      position: absolute;
      top: 3px;
      left: -30px;
    }
  }
}

div.roleSkillSelect {
  border: solid 2px #0fe2af;
  transition: all 0.3s ease;
}

div.roleSkillSelect:hover {
  border: solid 2px #29fcc9;
}

.roleSkillSelect {
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 15px;
  border-radius: 5px;

  :global {
    .root-node {
      width: 100% !important;
      height: 50px !important;
    }

    .react-selectize-control {
      height: 100% !important;
      border: 0 !important;

      @media all and (min-width: $screen-sm) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }

    .react-selectize-placeholder,
    .resizable-input {
      padding: 7px !important;
      color: #666 !important;
    }

    .react-selectize-search-field-and-selected-values input.resizable-input {
      padding: 0 !important;
      line-height: 40px !important;
    }

    .dropdown-menu {
      font-size: 16px !important;
    }

    .react-selectize-toggle-button-container {
      position: relative !important;
      top: 8px !important;
      display: none !important;
    }
  }
}
