@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.container {
  height: 250px;
  position: relative;
  max-width: 400px;
  margin: 30px auto;

  @media all and (min-width: $screen-md) {
    width: 400px;
    height: 450px;
    margin: 12px auto;
    max-width: none;
  }

  .slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .logoContainer {
      position: relative;
      height: 320px;
      margin-bottom: 10px;

      .smallLogoContainer,
      .largeLogoContainer {
        // border-bottom: 2px solid #ccc;
        background-color: #fff;
        position: absolute;
        padding: 20px;

        img {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          max-width: 100%;
          max-height: 100%;
        }
      }

      .smallLogoContainer {
        width: 110px;
        height: 110px;
        left: 290px;
        opacity: 0;
        border-radius: 10px;
        box-shadow: 5px 5px 8px #e5e5e5, -5px -5px 8px #ffffff;
        transform: translateY(20%);
        transition: all 0.5s ease;
        transition-property: opacity, transform;

        &.smallLogoContainerTop {
          top: 40px;
        }

        &.smallLogoContainerBottom {
          top: 170px;
        }
      }

      .largeLogoContainer {
        width: 280px;
        height: 280px;
        top: 20px;
        left: 20px;
        border-radius: 20px;
        box-shadow: 10px 10px 10px #bfbfbf, -10px -10px 10px #ffffff;
        opacity: 0;
        transform: scale(0.8);
        transition: all 0.75s ease;
        transition-property: opacity, transform;
      }
    }

    .userContainer {
      float: right;

      &:after {
        content: '';
        display: table;
        clear: both;
      }

      img {
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%;
        overflow: hidden;
        width: 65px;
        height: 65px;
        opacity: 0;
        transition: all 0.5s ease;
        transition-property: opacity;
      }

      .quote {
        background: #fefefe;
        padding: 20px 25px;
        display: inline-block;
        border-radius: 10px;
        // box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.09);
        box-shadow: 5px 5px 8px #cbcbcb, -5px -5px 8px #ffffff;
        font-size: 14px;
        max-width: 250px;
        text-align: center;
        margin-right: 20px;
        vertical-align: middle;
        position: relative;
        opacity: 0;
        transition: all 0.5s ease;
        transition-property: opacity, transform;

        &:after {
          top: calc(50% - 12px);
          left: 100%;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-left-color: #ffffff;
          border-width: 12px;
        }
      }
    }

    .logoContainerMobile {
      position: relative;
      height: 200px;

      .smallMobileLogoContainer,
      .mediumMobileLogoContainer,
      .largeMobileLogoContainer {
        border-bottom: 2px solid #ccc;
        background-color: #fff;
        position: absolute;
        padding: 20px;
        opacity: 0;
        transform: translateX(-50%);
        transition: all 0.5s ease;
        transition-property: opacity, transform;

        img {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          max-width: 100%;
          max-height: 100%;
        }
      }

      .smallMobileLogoContainer {
        width: 110px;
        height: 110px;
        left: 0;
        top: 45px;
      }

      .mediumMobileLogoContainer {
        width: 150px;
        height: 150px;
        left: calc(((100% - 460px)) / 2 + 110px);
        top: 25px;
      }

      .largeMobileLogoContainer {
        width: 200px;
        height: 200px;
        right: 0;
        box-shadow: 0 0 25px #bbb;
      }
    }

    &.showing {
      .smallLogoContainer {
        opacity: 1;
        transform: translateY(0%);

        &.smallLogoContainerTop {
          transition-delay: 0.25s;
        }

        &.smallLogoContainerBottom {
          transition-delay: 0.5s;
        }
      }

      .largeLogoContainer {
        opacity: 1;
        transform: scale(1);
        transition-delay: 0.75s;
      }

      .userContainer {
        img {
          opacity: 1;
          transition-delay: 0.75s;
        }

        .quote {
          opacity: 1;
          transition-delay: 0.75s;
        }
      }

      .smallMobileLogoContainer {
        opacity: 1;
        transform: translateX(0%);
      }

      .mediumMobileLogoContainer {
        opacity: 1;
        transform: translateX(0%);
        transition-delay: 0.5s;
      }

      .largeMobileLogoContainer {
        opacity: 1;
        transform: translateX(0%);
        transition-delay: 1s;
      }
    }
  }

  .counter {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;

    & > div {
      background-color: #999;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 0 7px;
      display: inline-block;
      cursor: pointer;

      &.currentSlide {
        background-color: $brand-success;
      }
    }
  }
}
