@import 'src/theme/variables.scss';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.noGradient {
  background: $brand-navy;
}

.gradient {
  background: $brand-navy;
  position: relative;
  z-index: 1;

  &:before {
    background: inherit;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
    -webkit-backface-visibility: hidden; // for Chrome Windows
  }
}

.gradientRight {
  &:before {
    top: 0;
    transform: skewY(178deg);
    transform-origin: 0;
  }
}

.gradientLeft {
  &:before {
    top: 0;
    transform: skewY(2deg);
    transform-origin: 100%;
  }
}

.container {
  text-align: center;
  padding: 1.5em;
}

.title {
  color: white;
  font-size: 30px;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);

  &.hide {
    transform: translateY(10%);
    opacity: 0;
  }
}

.sections {
  color: white;
  text-align: left;
}

.section {
  margin-bottom: 2em;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);

  @media (min-width: $screen-sm) {
    padding: 10px 40px;
  }

  &.hide {
    transition-delay: 0s;
    transform: translateY(20%);
    opacity: 0;
  }

  &:nth-child(1) {
    transition-delay: 0.1s;
  }

  &:nth-child(2) {
    transition-delay: 0.2s;
  }

  &:nth-child(4) {
    transition-delay: 0.3s;
  }

  &:nth-child(6) {
    transition-delay: 0.4s;
  }

  &:nth-child(8) {
    transition-delay: 0.5s;
  }

  &:nth-child(9) {
    transition-delay: 0.6s;
  }

  p {
    &:nth-child(1) {
      font-size: 20px;

      a {
        color: #fff;
      }
    }

    &:nth-child(2) {
      font-size: 16px;
    }

    &:nth-child(3) {
      a {
        color: white;
      }

      text-decoration: underline;
    }
  }
}
