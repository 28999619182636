@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.noGradient {
  background: $brand-navy;
}

.gradient {
  background: $brand-navy;
  position: relative;
  z-index: 1;

  &:before {
    background: inherit;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
    -webkit-backface-visibility: hidden; // for Chrome Windows
  }
}

.gradientRight {
  &:before {
    top: 0;
    transform: skewY(178deg);
    transform-origin: 0;
  }
}

.gradientLeft {
  &:before {
    top: 0;
    transform: skewY(2deg);
    transform-origin: 100%;
  }
}

.container {
  text-align: center;
  padding: 1.5em;
  overflow: hidden;
}

.title {
  color: white;
  font-size: 30px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: 700;
  text-transform: uppercase;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);

  @media (min-width: $screen-sm) {
    margin-bottom: 1.5em;
  }

  &.hide {
    transform: translateY(10%);
    opacity: 0;
  }
}

.textContainer {
  margin-top: 40px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font-size: 18px;
  color: white;

  .points {
    text-align: left;
    opacity: 1;

    @media (min-width: $screen-sm) {
      padding-left: 20%;
    }

    p {
      transform: translateY(0px);
      opacity: 1;
      transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);
      margin-right: 20px;
      margin-bottom: 30px;
      color: white;
      margin-left: 25px;
      font-size: 20px;

      span {
        display: inline-block;
        vertical-align: middle;
      }

      @media (max-width: $screen-sm) {
        padding-left: 50px;
      }

      &:nth-child(2) {
        transition-delay: 0.2s;
      }

      &:nth-child(3) {
        transition-delay: 0.4s;
      }

      &.hide {
        transition-delay: 0s;
        transform: translateY(10%);
        opacity: 0;
      }

      .numbers {
        margin-left: -65px;
        font-size: 22px;
        border: 2px solid;
        border-radius: 50%;
        padding: 0.1em 0.6em;
        margin-right: 15px;
      }
    }
  }

  .cta {
    display: block;
    margin-top: 20px;
    margin-bottom: 2em;
    transform: translateY(0px);
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);
    transition-delay: 0.6s;

    button {
      color: white;
      border-color: white;
      padding: 10px 70px;
    }

    &.hide {
      transition-delay: 0s;
      transform: translateY(10%);
      opacity: 0;
    }
  }
}

.videoContainer {
  cursor: pointer;
  transform: translateX(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);
  transition-delay: 0.2s;

  img {
    max-width: 100%;
  }

  video {
    position: absolute;
    left: 7%;
    top: 5%;
    width: 86%;
    border-radius: 10px;
    background-color: transparent !important;

    @media (min-width: $screen-sm) {
      top: 5%;
    }

    @media (min-width: $screen-md) {
      left: 6%;
      top: 5%;
      width: 88%;
    }

    @media (min-width: $screen-lg) {
      left: 5%;
      width: 90%;
    }
  }

  .playButton {
    text-align: center;
    background-color: #000;
    border-radius: 50%;
    opacity: 0.75;
    position: absolute;
    width: 90px;
    height: 90px;
    left: calc(50% - 45px);
    top: calc(40% - 45px);
    line-height: 130px;

    svg {
      position: relative;
      left: 5px;
    }
  }

  &.hide {
    transition-delay: 0s;
    transform: translateX(10%);
    opacity: 0;
  }
}
