@import 'src/theme/variables.scss';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.noGradient {
  background: $brand-info;
}

.gradient {
  background: $brand-info;
  position: relative;
  z-index: 1;

  &:before {
    background: inherit;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
    -webkit-backface-visibility: hidden; // for Chrome Windows
  }
}

.gradientRight {
  &:before {
    top: 0;
    transform: skewY(178deg);
    transform-origin: 0;
  }
}

.gradientLeft {
  &:before {
    top: 0;
    transform: skewY(2deg);
    transform-origin: 100%;
  }
}

.container {
  text-align: center;
  padding: 1.5em;
}

.title {
  color: $brand-purple;
  font-size: 30px;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);

  &.hide {
    transform: translateY(10%);
    opacity: 0;
  }
}

.sections {
  :global {
    .row {
      &:nth-child(even) {
        .col-sm-6 {
          &:nth-child(odd) {
            left: 0;

            @media (min-width: $screen-sm) {
              left: 50%;
            }
          }

          &:nth-child(even) {
            right: 0;

            @media (min-width: $screen-sm) {
              right: 50%;
            }
          }
        }
      }

      &:last-child {
        border-bottom: medium none;
      }
    }
  }
}
