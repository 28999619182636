@import 'src/theme/variables.scss';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.section {
  margin: 1em;
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid #ccc;

  @media (min-width: $screen-sm) {
    padding: 50px 0;
  }
}

.image {
  display: inline-block;
  vertical-align: middle;
  float: none;
  transform: translateX(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);

  img {
    max-width: 80%;
    max-height: 185px;

    @media (max-width: $screen-sm) {
      max-width: 100%;
    }
  }

  &.hide {
    transform: translateX(-10%);
    opacity: 0;
  }
}

.text {
  display: inline-block;
  vertical-align: middle;
  float: none;
  transform: translateX(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);

  @media (max-width: $screen-sm) {
    margin-top: 1.5em;
  }

  &.hide {
    transform: translateX(10%);
    opacity: 0;
  }
}

.title {
  font-size: 20px;
}

.subtitle {
  font-size: 16px;
}
