@import 'src/theme/variables.scss';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.noGradient {
  background: $brand-purple;
}

.gradient {
  background: $brand-purple;
  position: relative;
  z-index: 1;

  &:before {
    background: inherit;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
    -webkit-backface-visibility: hidden; // for Chrome Windows
  }
}

.gradientRight {
  &:before {
    top: 0;
    transform: skewY(178deg);
    transform-origin: 0;
  }
}

.gradientLeft {
  &:before {
    top: 0;
    transform: skewY(2deg);
    transform-origin: 100%;
  }
}

.container {
  text-align: center;
  padding-top: 5em;
  overflow: hidden;
}

.infoContainer {
  color: white;
  display: inline-block;
  transform: translateX(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);
  padding-bottom: 2.5em;

  @media (min-width: $screen-sm) {
    text-align: left;
    padding-bottom: 0;
  }

  @media (min-width: $screen-lg) {
    padding-left: 100px;
  }

  h2 {
    font-size: 22px;
    margin: 0;
    color: white;
  }

  p {
    font-size: 16px;
    margin-top: 10px;

    a {
      color: white;
      text-decoration: underline;
    }
  }

  &.hide {
    transform: translateX(10%);
    opacity: 0;
  }
}

.imageContainer {
  display: inline-block;
  float: none;
  transform: translateX(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);

  img {
    max-width: 100%;
    max-height: 350px;
  }

  &.hide {
    transform: translateX(-10%);
    opacity: 0;
  }
}
