@import 'src/theme/variables.scss';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.noGradient {
  background: white;
}

.gradient {
  background: white;
  position: relative;
  z-index: 1;

  &:before {
    background: inherit;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
    -webkit-backface-visibility: hidden; // for Chrome Windows
  }
}

.gradientRight {
  &:before {
    top: 0;
    transform: skewY(178deg);
    transform-origin: 0;
  }
}

.gradientLeft {
  &:before {
    top: 0;
    transform: skewY(2deg);
    transform-origin: 100%;
  }
}

.container {
  text-align: center;
  padding: 1.5em;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.6em;
  color: white;
  margin-bottom: 1em;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);

  &.hide {
    transform: translateY(-10%);
    opacity: 0;
  }
}

.button {
  font-size: 16px;
  margin-bottom: 1.25em;
  padding: 10px 50px;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);

  &.hide {
    transform: translateY(10%);
    opacity: 0;
  }
}
