@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.modal {
  width: 90%;

  @media all and (min-width: $screen-md) {
    width: 75%;
  }

  @media all and (min-width: $screen-lg) {
    width: 60%;
  }
}
