@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.containerIllustration {
  padding-left: 30px;
  padding-right: 30px;
}

.col {
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.5s ease;

  &.hide {
    transform: translateY(10%);
    opacity: 0;
  }
}
