@import 'src/theme/variables.scss';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.container {
  text-align: center;
}

.title {
  font-size: 20px;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);

  &.hide {
    transform: translateY(10%);
    opacity: 0;
  }
}

.testimonials {
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);

  &.hide {
    transform: translateY(-10%);
    opacity: 0;
  }

  :global {
    .slick-dots {
      button:before {
        font-size: 10px !important;
      }
    }

    .slick-active {
      button:before {
        font-size: 10px !important;
        color: $brand-primary !important;
      }
    }
  }
}

.testimonial {
  position: relative;
  margin: 25px 0;
}

.testimonialImage {
  margin: 0 auto;
}

.testimonialText {
  position: absolute;
  max-width: 320px;
  left: calc(50% - 160px);
  top: 20%;
}

.testimonialTitle {
  quotes: '“' '”' '‘' '’';

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
}

.testimonialUser {
  font-weight: 700;
  color: $brand-darkgrey;
}
