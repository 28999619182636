@import 'src/theme/variables.scss';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.freelancerBox {
  font-size: 18px;
  border: 2px solid #eeeeee;
  text-align: center;
  padding: 8px;
  border-radius: 5px;
  color: $brand-purple;
  margin: 20px auto 0px auto;
  background-color: white;

  // @media (min-width: $screen-sm) {
  //   max-width: 500px;
  // }
}
