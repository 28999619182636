@import 'src/theme/variables.scss';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.noGradient {
  background: white;
}

.gradient {
  background: white;
  position: relative;
  z-index: 1;

  &:before {
    background: inherit;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
    -webkit-backface-visibility: hidden; // for Chrome Windows
  }
}

.gradientRight {
  &:before {
    top: 0;
    transform: skewY(178deg);
    transform-origin: 0;
  }
}

.gradientLeft {
  &:before {
    top: 0;
    transform: skewY(2deg);
    transform-origin: 100%;
  }
}

.container {
  text-align: center;
  padding: 1.5em;

  p {
    max-width: 800px;
    margin: 0 auto;
  }
}

.title {
  color: $brand-purple;
  font-size: 30px;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);

  &.hide {
    transform: translateY(10%);
    opacity: 0;
  }
}

.subtitle {
  font-size: 20px;
  margin-bottom: 1.5em;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);

  &.hide {
    transform: translateY(-10%);
    opacity: 0;
  }
}

.seeMore {
  font-size: 16px;
  margin-top: 1.5em;
  color: $brand-purple;
  border-color: $brand-purple;
  border-width: 2px;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);

  &.hide {
    transform: translateY(10%);
    opacity: 0;
  }
}

.freelancerBox {
  font-size: 18px;
  border: 2px solid #ff995c;
  text-align: center;
  padding: 8px;
  border-radius: 5px;
  color: #ff995c;
  max-width: 80%;
  margin: 20px auto 0px auto;

  @media (min-width: $screen-sm) {
    max-width: 500px;
  }

  a {
    text-decoration: underline;
    color: #ff995c;
  }
}
