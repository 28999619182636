@import 'src/theme/variables.scss';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.noGradient {
  background: $body-bg;
}

.gradient {
  background: $body-bg;
  position: relative;
  z-index: 1;

  &:before {
    background: inherit;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
    -webkit-backface-visibility: hidden; // for Chrome Windows
  }
}

.gradientRight {
  &:before {
    top: 0;
    transform: skewY(178deg);
    transform-origin: 0;
  }
}

.gradientLeft {
  &:before {
    top: 0;
    transform: skewY(2deg);
    transform-origin: 100%;
  }
}

.container {
  text-align: center;
  padding: 2.5em 0.5em;
}

.imageContainer {
  margin: 15px 0;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.56, 0.37, 0.44, 1.42);

  &:nth-child(1) {
    transition-delay: 0.15s;
  }

  &:nth-child(2) {
    transition-delay: 0.3s;
  }

  &:nth-child(3) {
    transition-delay: 0.45s;
  }

  &:nth-child(4) {
    transition-delay: 0.45s;
  }

  &:nth-child(5) {
    transition-delay: 0.3s;
  }

  &:nth-child(6) {
    transition-delay: 0.15s;
  }

  @media screen and (min-width: $screen-md) {
    margin: 0;
  }

  img {
    max-width: 100%;
    height: auto;
    position: relative;
  }

  &.hide {
    transition-delay: 0s;
    transform: translateY(10%);
    opacity: 0;
  }
}
